import {
  DynamoDBDocumentClient,
  ScanCommand,
  QueryCommand,
} from "@aws-sdk/lib-dynamodb";
import { DynamoDBClient, QueryCommandInput, QueryCommand as QueryCommandClient } from "@aws-sdk/client-dynamodb";
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetIdCommandOutput,
} from "@aws-sdk/client-cognito-identity"; // ES Modules import
import VideoObject from "../interfaces";

const timeInterval = 900000;

// export const shuffleArray = (array: any[]) => {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     const temp = array[i];
//     array[i] = array[j];
//     array[j] = temp;
//   }
// };

// Config to connect to AWS DynamoDb service hosted on our AWS organization account
console.log( process.env.REACT_APP_ACCESS_KEY, process.env.REACT_APP_SECRET_KEY)
const config = {
  region: 'eu-central-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  },
};

// @ts-ignore
const dynamoDBClient = new DynamoDBClient(config);
const docClient = DynamoDBDocumentClient.from(dynamoDBClient);

function localStorageAvailable() {
  let storage;
  try {
    storage = window["localStorage"];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

async function getVideos(setVideoOrder: any) {
  const lastFetchedTime = localStorage.getItem("lastFetched");
  if (
    (lastFetchedTime &&
      parseInt(lastFetchedTime) + timeInterval <= Date.now()) ||
    lastFetchedTime === null
  ) {
    try {
      // Scans all items in table but filters and returns only active elements
      const command = new QueryCommand({
        TableName: "vidquiz",
        KeyConditionExpression: "clientName = :a",
        ExpressionAttributeValues: { ":a": "365Scores", ":activeValue": true },
        FilterExpression: "active = :activeValue", // Filter by the "active" field
        ConsistentRead: true,
      });
      const res = await docClient.send(command);

      console.log("res", res?.Items);

      const vidsSeenJSON = localStorage.getItem("videosSeen");
      if (vidsSeenJSON) {
        // videos use has seen stored in local storage
        const vidsSeen: string[] = JSON.parse(vidsSeenJSON);
        const set1 = new Set(vidsSeen);
        console.log("set1", set1);
        // scanned videos from dynamoDB
        const videos: VideoObject[] = res?.Items as VideoObject[];

        // updates videos to see in localstorage to whatever is set as active in dynamodb
        // meanign each time the user loads the app. new active videos will be added. and videos that change from
        // active to unactive will be removed from local storage
        const videosToSee: VideoObject[] = [];

        for (const video of videos) {
          if (
            video.source === "clearLocalStorage" ||
            video.source === "emailInput"
          ) {
            continue;
          }
          if (!set1.has(video.source)) {
            // shuffleArray(video.options);
            videosToSee.push(video);
          }
        }

        videosToSee.reverse();

        // shuffleArray(videosToSee);
        setVideoOrder(videosToSee || []);
        localStorage.setItem("videosToSee", JSON.stringify(videosToSee));
        localStorage.setItem("lastFetched", JSON.stringify(Date.now()));
      } else {
        console.log("videosSeen doesnt exist in local storage ");
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export async function getIdentityId(setLoading: any, setVideoOrder: any) {
  try {
    if (localStorageAvailable()) {
      if (localStorage.getItem("identityId")) {
        await getVideos(setVideoOrder);
        setLoading(false);
        console.log(localStorage.getItem("videosToSee"));
        const json = localStorage.getItem("videosToSee");
        if (json) setVideoOrder(JSON.parse(json));
        console.log("set local video");
        return;
      }
      const input = {
        // GetIdInput
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, // required
      };
      const config = {
        region: process.env.REACT_APP_REGION,
      };
      const client = new CognitoIdentityClient(config); // create client
      const command = new GetIdCommand(input); // create command to get playerId
      const response: GetIdCommandOutput = await client.send(command); // call command
      if (response.IdentityId) {
        localStorage.setItem("identityId", response.IdentityId);
        localStorage.setItem("videosSeen", JSON.stringify([]));
        localStorage.setItem("points", "0");
        localStorage.setItem("videosToSee", JSON.stringify([]));
        await getVideos(setVideoOrder);
        setLoading(false);
      } else {
        console.log("could not assign indetitiyID");
      }
    } else {
      console.log("Could not save identityId to local storage ");
    }
  } catch (error) {
    console.log(error);
    console.log("failed to get Id");
  }
}

// This function checks to see if Clear Local Storage is set for user.
// Having this on will allow user to see the same video again.
export const getCurrentState = async (sourceValue: string) => {
  console.log('current state')
  const params = {
    TableName: "vidquiz",
    KeyConditionExpression: "clientName = :pk and #source = :sk",
    ExpressionAttributeNames: { "#source": "source" },
    ExpressionAttributeValues: {
      ":pk": { S: "365Scores" },
      ":sk": { S: sourceValue },
    },
  };
  const command = new QueryCommandClient(params);
  try {
    // @ts-ignore
    const { Items } = await docClient.send(command);

    console.log('Items', Items)
    if(Items)
    return Items[0]?.active?.BOOL || Items[0].title?.S;
  } catch (err) {
    // console.log(err);
  }
};
