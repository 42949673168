import React, { MutableRefObject, useRef, useState } from "react";
import "./Popup.css";
import Countdown from "react-countdown";
import VideoObject from "../interfaces";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Incorrect =
  "https://res.cloudinary.com/shulgirit/video/upload/v1688412402/wiply/Clients/stari/incorrect_tfcegn.wav";

interface PopupProps {
  video: VideoObject | undefined;
  onSelectOption: (option: {
    id: number;
    text: string;
    correct: boolean;
  }) => void;
  setShowPopup: (input: boolean) => void;
  setPlaying: (input: boolean) => void;
  setPopupDisplayed: (input: boolean) => void;
  videoData: VideoObject[] | undefined | any;
  countdownAudio: MutableRefObject<HTMLAudioElement | undefined>;
  selectedOption:
    | {
        id: number;
        text: string;
        correct: boolean;
      }
    | undefined;
}

const Completionist = () => <span>זמן תם</span>;

const Popup: React.FC<PopupProps> = ({
  video,
  videoData,
  onSelectOption,
  setShowPopup,
  setPlaying,
  setPopupDisplayed,
  countdownAudio,
  selectedOption,
}) => {
  const incorrectAudio = useRef(new Audio(Incorrect));

  const [clock, setClock] = useState(true);

  const renderer = ({
    seconds,
    completed,
  }: {
    seconds: any;
    completed: any;
  }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      const renderTime = () => {
        return (
          <div className="timer">
            <div className="value">{seconds}</div>
          </div>
        );
      };
      return (
        clock && (
          <CountdownCircleTimer
            isPlaying
            duration={Number(timePausedLength) || 8}
            size={80}
            colors={["#03afd8", "#03afd8", "#03afd8", "#03afd8"]}
            colorsTime={[10, 6, 3, 0]}
            onComplete={() => ({ shouldRepeat: true, delay: 1 })}
          >
            {renderTime}
          </CountdownCircleTimer>
        )
      );
    }
  };

  if (!video) return null;

  const { question, timePausedLength, options, imageOptions }: VideoObject =
    video;

  return (
    <div className="popup">
      <div className="popup-overlay" />
      <div className="countdown">
        <Countdown
          date={Date.now() + (Number(timePausedLength) || 8) * 1000}
          renderer={renderer}
          onComplete={() => {
            // if user doesn't select option, we iterate through option to find the correct one, then display to user correct one
            // TODO: [WS-4] Countdown restarts instead of disapper after choosing option When the player chooses one of the options the COUNTDOWN is restarting instead of disapper
            console.log(countdownAudio.current);

            //@ts-ignore
            countdownAudio.current.stop();

            for (const option of options) {
              if (option.correct) {
                if (videoData) {
                  const videos = [...videoData];

                  incorrectAudio.current.play();

                  const video = videos.pop();

                  const videoSeenJSON = localStorage.getItem("videosSeen");
                  if (videoSeenJSON) {
                    const videoSeen = JSON.parse(videoSeenJSON);
                    videoSeen.push(video?.source);
                    localStorage.setItem(
                      "videosSeen",
                      JSON.stringify(videoSeen)
                    );
                  }

                  localStorage.setItem("videosToSee", JSON.stringify(videos));

                  const el = document.getElementById("option" + option.id);
                  if (el === null) return;
                  let green = true;
                  let interval = setInterval(() => {
                    if (green) {
                      el.style.backgroundColor = "#028A0f";
                      green = false;
                    } else {
                      el.style.backgroundColor = "white";
                      green = true;
                    }
                  }, 200);
                  setTimeout(() => {
                    clearInterval(interval);
                    setShowPopup(false);
                    setPlaying(true);
                    setPopupDisplayed(true);
                  }, 1600);
                }
              }
            }
          }}
        />
      </div>
      <div className="popup-content">
        <p className="popup-question">{question}</p>
        <div className="options">
          {/* {CountDown} */}
          <Options
            imageOptions={imageOptions}
            options={options}
            onSelectOption={onSelectOption}
            setClock={setClock}
            selectedOption={selectedOption}
          />
        </div>
        <div
          className="prize-text"
          style={{ marginTop: "15px", fontSize: "20px" }}
        >
          {/* <div style={{ textTransform: "uppercase" }}>{prize1}</div>
            <div style={{ textTransform: "uppercase" }}>{prize2}</div> */}
        </div>
      </div>
    </div>
  );
};

interface OptionsProps {
  options: {
    id: number;
    text: string;
    correct: boolean;
  }[];
  imageOptions: boolean | undefined;
  onSelectOption: (option: {
    id: number;
    text: string;
    correct: boolean;
  }) => void;
  setClock: (clock: boolean) => void;
  selectedOption:
    | {
        id: number;
        text: string;
        correct: boolean;
      }
    | undefined;
}

const Options: React.FC<OptionsProps> = ({
  imageOptions,
  options,
  onSelectOption,
  setClock,
  selectedOption,
}) => {
  return (
    <>
      {imageOptions
        ? options.map((option) => (
            <img
              className="image-options"
              width={100}
              height={100}
              id={"option" + option.id}
              alt="gameImage"
              src={option.text}
              key={option.id}
              onClick={() => {
                onSelectOption(option);
                setClock(false);
              }}
            />
          ))
        : options.map((option, index) => {
            if (!option.text) {
              return null;
            }
            return (
              <button
                disabled={selectedOption !== undefined}
                id={"option" + option.id}
                key={option.id}
                onClick={() => {
                  onSelectOption(option);
                  setClock(false);
                }}
              >
                {/* <span className="answer-circle">
                  {String.fromCharCode(0x5d0 + index)}
                </span> */}
                {option.text}
              </button>
            );
          })}
    </>
  );
};

export default Popup;
