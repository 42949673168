import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import awsExports from './aws-exports';
// Amplify.configure(awsExports);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <App />
);
