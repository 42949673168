import React, { useEffect, useRef, useState, memo } from "react";
import ReactPlayer from "react-player";
import Popup from "./Popup";
import WinCoupon from "./coupon";
import "../App.css";
import VideoObject from "../interfaces";
import WelcomeScreen from "./WelcomeScreen";

const Correct ="https://res.cloudinary.com/shulgirit/video/upload/v1688412401/wiply/Clients/stari/correct_srrxmo.wav";
const Incorrect ="https://res.cloudinary.com/shulgirit/video/upload/v1688412402/wiply/Clients/stari/incorrect_tfcegn.wav";
const countdownMusic ="https://res.cloudinary.com/shulgirit/video/upload/v1688412402/wiply/Clients/stari/countdown_fmebl2.mp3";
const winningAudio ="https://res.cloudinary.com/shulgirit/video/upload/v1693340032/wiply/vidquiz/winning-08-29_at_4.12.27_PM_tt16o5.mp3";
const losingAudio ="https://res.cloudinary.com/shulgirit/video/upload/v1693340576/wiply/vidquiz/losing-08-29_at_4.18.06_PM_gw4dj4.mp3";

var soundID = "CoundDownMusic";

interface VideoPlayerProps {
  videoData: VideoObject[];

  showWinCoupon: boolean;
  setShowWinCoupon: (input: boolean) => void;
  setVideoData: any
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoData,
  showWinCoupon,
  setShowWinCoupon,
  setVideoData
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupDisplayed, setPopupDisplayed] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<
    { id: number; text: string; correct: boolean } | undefined
  >();

  const [started, setStarted] = useState<boolean>(false);

  // Get the last video from all the videos you send to this component

  const [currVideo, setCurrVideo] = useState(videoData.length - 1);
  const [video, setVideo] = useState(videoData && videoData[currVideo]);

  const [cachbustUrl, setCachbustUrl] = useState(
    `${video?.source}?${Date.now()}`
  );

  const correctAudio = useRef(new Audio(Correct));
  const incorrectAudio = useRef(new Audio(Incorrect));
  const countdownAudio = useRef();
  const winningRef = useRef(new Audio(winningAudio));
  const losingRef = useRef(new Audio(losingAudio))
  const [videoEnded, setVideoEnded] = useState(false)

  const [playing, setPlaying] = useState<boolean>(false);

    // useEffect(() => {
    //   // const vidSource: string[] = videoData.map((data: VideoObject) => data.source)[]


    //     const preloadElement = document.createElement('link')
    //     preloadElement.rel="preload"
    //     console.log(currVideo)
    //     const futureVid = videoData[currVideo - 1]
    //     console.log(futureVid)

    //     preloadElement.href= futureVid && futureVid?.source
    //     preloadElement.as="video"
    //     document.head.appendChild(preloadElement)
    //     console.log('appended child')
   

    // }, [currVideo])
  
  useEffect(() => {
    console.log(currVideo, videoData.length - 1)
    if (currVideo !== videoData.length - 1) {
      setVideoEnded(false)
      setVideo(videoData && videoData[currVideo]);
      setCachbustUrl(
        `${videoData && videoData[currVideo]?.source}?${Date.now()}`
      );
      setShowWinCoupon(false);
      setPopupDisplayed(false);
      setSelectedOption(undefined)
      const videos = [...videoData];
      videos.pop();
      setVideoData(videos)
      const preloadElement = document.createElement('link')
      preloadElement.rel="preload"
      const futureVid = videoData[currVideo + 1]
      preloadElement.href= futureVid && futureVid?.source
      preloadElement.as="video"
      document.head.appendChild(preloadElement)
      console.log('appended child')
    }
  }, [currVideo]);

  useEffect(() => {
    //@ts-ignore
    createjs.Sound.registerSound(countdownMusic, soundID);
  }, []);

  const props = {
    setShowPopup,
    setPlaying,
    setPopupDisplayed,
  };

  const handleVideoProgress = (progress: any) => {
    const { playedSeconds } = progress;
    const freezeTime = video?.timePaused || 1; // Adjust the freeze time as needed

    if (playedSeconds >= freezeTime && !showPopup && !popupDisplayed) {
      console.log("video paused");
      pauseVideo();
      //@ts-ignore
      countdownAudio.current = createjs.Sound.play(soundID);
    }
  };

  const pauseVideo = () => {
    setShowPopup(true);
    setPlaying(false);
  };

  // console.log('video data', videoData)
  const handleSelectOption = (option: {
    id: number;
    text: string;
    correct: boolean;
  }) => {
    // @ts-ignore
    countdownAudio.current.stop();

    // Handle the selected option
    console.log("Selected option:", option);
    // we get option which user select and store it to show right type of coupon
    setSelectedOption(option);

    // remove the video from localstorage once they selected an option
    if (videoData) {
      const videos = [...videoData];
      const video = videos.pop();

      const videoSeenJSON = localStorage.getItem("videosSeen");
      if (videoSeenJSON) {
        const videoSeen = JSON.parse(videoSeenJSON);
        videoSeen.push(video?.source);
        localStorage.setItem("videosSeen", JSON.stringify(videoSeen));
      }

      localStorage.setItem("videosToSee", JSON.stringify(videos));

      // we get the html element and display the wrong or right option to user
      const el = document.getElementById("option" + option.id);
      if (el === null) return;
      let color: string;
      let flag = true;

      if (option.correct) {
        color = "#03afd8";
        correctAudio.current.play();
      } else {
        color = "red";
        incorrectAudio.current.play();
      }

      let interval = setInterval(() => {
        if (flag) {
          el.style.backgroundColor = color;
          flag = false;
        } else {
          el.style.backgroundColor = "white";
          flag = true;
        }
      }, 200);
      setTimeout(() => {
        clearInterval(interval);
        setShowPopup(false);
        setPlaying(true);
        setPopupDisplayed(true);
      }, 1600);
    }
  };

  const handleGameEnd = () => {
    // Logic to determine when the game ends

    const pointsStorage = localStorage.getItem("points");
    console.log(pointsStorage);

    if (selectedOption?.correct) {
      setShowWinCoupon(true);
      // correctAudio.current.play();
      winningRef.current.play();
      setTimeout(() => {
        winningRef.current.pause();
      }, 4000);
      if (pointsStorage !== null)
        localStorage.setItem("points", `${parseInt(pointsStorage) + 1}`);
    } else {
      losingRef.current.play();
    }

    // after we show coupon we then update localstorage so when user comes back we don't show same video again
    setVideoEnded(true)
  };

  return (
    <>
      {!started && (
        <WelcomeScreen
          setPlaying={setPlaying}
          setStarted={setStarted}
          poster={video?.poster}
        />
      )}
      <div className="video-player">
        <ReactPlayer
        id="video"
          className="react-player"
          progressInterval={50}
          width="100%"
          height="100%"
          onEnded={handleGameEnd}
          playsinline={true}
          config={{
            file: {
              attributes: {
                style: {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "30px",
                },
              },
            },
          }}
          url={cachbustUrl}
          playing={playing}
          onProgress={handleVideoProgress}
        />
        {showPopup && (
          <Popup
            video={video}
            videoData={videoData}
            selectedOption={selectedOption}
            onSelectOption={handleSelectOption}
            countdownAudio={countdownAudio}
            {...props}
          />
        )}

        {videoEnded  &&  <WinCoupon
            correctAnswer={showWinCoupon}
            currVideo={currVideo}
            setCurrVideo={setCurrVideo}
            winningRef={winningRef}
            lastVideo={videoData?.length === 1}
          />}
    
      </div>
    </>
  );
};

export default memo(VideoPlayer);
