import React, { useEffect, useState } from "react";
import "./WelcomeScreen.css";
import WallaLogo from "../assets/wallalogo.png";

import { getCurrentState } from "./utilities";

interface WelcomeScreenProps {
  setPlaying: (playing: boolean) => void;
  setStarted: (playing: boolean) => void;
  poster: string | undefined;
}

const logo =
  "https://res.cloudinary.com/shulgirit/image/upload/v1706093768/wiply/vidquiz/365scores/27952262779_eklmok.png";

const StartClick =
  "https://res.cloudinary.com/shulgirit/video/upload/v1688412402/wiply/Clients/stari/startClick_va9mvb.mp3";

var soundID = "StartClick";

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({
  setStarted,
  setPlaying,
  poster,
}) => {
  useEffect(() => {
    // @ts-ignore
    createjs.Sound.registerSound(StartClick, soundID);
  }, []);

  const handleStart = () => {
    // ref.current.play();
    //@ts-ignore
    createjs.Sound.play(soundID);

    setStarted(true);
    setPlaying(true);
  };

  const isMobile = window.innerWidth < window.innerHeight;
  return (
    <div
      // className="welcome-screen player-wrapper"
      onClick={handleStart}
      style={{ background: "transparent", zIndex: 100000 }}
    >
      <div className="welcome-overlay" />
      {poster && (
        <div className="poster" style={{ height: "100%", width: "100%" }}>
          <img
            style={{ height: "100%", borderRadius: "30px" }}
            width="100%"
            src={poster}
            alt="poster"
          ></img>
        </div>
      )}

      <div className="welcome-content">
        <div className="content">
          <button className="glow" onClick={handleStart}>
          שחק עכשיו
          </button>
        </div>
      </div>
      {!isMobile && (
        <img
          src={WallaLogo}
          // height={"70%"}
          // className="smalllogo"
          className="logo2"
          alt="VidQuiz"
        />
      )}
    </div>
  );
};

export default WelcomeScreen;
